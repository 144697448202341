import React from "react";
import logo from "../Pictures/pod_logo_one_white.png";
//import LayoutTwo from "./LayoutTwo";
import { useState } from "react";
import { Link } from "react-router-dom";

const LayoutOne = () => {
  const [content, setContent] = useState("");
  const [title, setTitle] = useState("");

  const handleHover = (props) => {
    let text = `Lorem ipsum dolor sit amet,
    consectetur adipiscing elit, sed do
    eiusmod tempor incididunt ut labore et
    dolore magna aliqua. Ut enim ad minim
    veniam, quis nostrud exercitation
    ullamco laboris nisi ut aliquip ex ea
    commodo consequat. Duis aute irure
    dolor in reprehenderit in voluptate
    velit esse cillum dolore eu fugiat
    nulla pariatur. Excepteur sint
    occaecat cupidatat non proident, sunt
    in culpa qui officia deserunt mollit
    anim id est laborum.`;

    if (props == 1) {
      text =
        "This is Layout 1.  A more responsive site driven by unique hover effects, open space, with focus on visuals over text.";
      setContent(text);
      setTitle("Layout 1");
    } else if (props == 2) {
      setContent(text);
      setTitle("Layout 2");
    } else if (props == 3) {
      setContent(text);
      setTitle("Layout 3");
    } else if (props == 4) {
      setContent(text);
      setTitle("Layout 4");
    } else {
      setTitle("Layouts");
      setContent(text);
    }
  };

  const handleMouseOut = () => {
    setContent("");
  };

  return (
    <div className="layoutOne-content">
      <div className="layoutOne-header">
        <div className="layoutOne-logo">
          <img src={logo} alt="logo" />
          <hr className="layoutOne-divider-line"></hr>
        </div>
      </div>
      <div className="layoutOne-body">
        <div className="layoutOne-body-left">
          <div
            className="layoutOne-body-left-item"
            onMouseOver={() => handleHover(1)}
            // onMouseOut={handleMouseOut}
          >
            <Link to="/LayoutOne">
              <h3>Layout 1</h3>
            </Link>
          </div>
          <div
            className="layoutOne-body-left-item"
            onMouseOver={() => handleHover(2)}
            // onMouseOut={handleMouseOut}
          >
            <Link to="/LayoutTwo">
              <h3>Layout 2</h3>
            </Link>
          </div>
          <div
            className="layoutOne-body-left-item"
            onMouseOver={() => handleHover(3)}
            // onMouseOut={handleMouseOut}
          >
            <Link to="/LayoutThree">
              <h3>Layout 3</h3>
            </Link>
          </div>
          <div
            className="layoutOne-body-left-item"
            onMouseOver={() => handleHover(4)}
            // onMouseOut={handleMouseOut}
          >
            <Link to="/LayoutFour">
              <h3>Layout 4</h3>
            </Link>
          </div>
        </div>
        <div className="layoutOne-body-right">
          <div className="layoutOne-body-right-container">
            <h3>{title}</h3>
            <hr />
            <hr />
            <p>{content}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LayoutOne;
