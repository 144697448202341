import React from "react";
import logo from "../Pictures/pod_logo_one_white.png";
import micPic from "../Pictures/matt-botsford-OKLqGsCT8qs-unsplash.jpg";
import { Link, useLocation } from "react-router-dom";

const LayoutTwo = () => {
  const location = useLocation();

  return (
    <>
      <div className="layoutTwo-content">
        <div className="layoutTwo-logo">
          <img src={logo} alt="logo" />
        </div>
        <div className="layoutTwo-nav">
          <div className="layoutTwo-nav-item">
            <Link to="/LayoutOne">
              <h3>Layout 1</h3>
            </Link>
          </div>
          <div className="layoutTwo-nav-item">
            <Link to="/LayoutTwo">
              <h3>Layout 2</h3>
            </Link>
          </div>
          <div className="layoutTwo-nav-item">
            <Link to="/LayoutThree">
              <h3>Layout 3</h3>
            </Link>
          </div>
          <div className="layoutTwo-nav-item">
            <Link to="/LayoutFour">
              <h3>Layout 4</h3>
            </Link>
          </div>
        </div>
        <div className="layoutTwo-body-content-space"></div>
        <div className="layoutTwo-body-content">
          <div className="layoutTwo-body-content-background">
            <div className="layoutTwo-body-content-background-pic-container">
              <div className="layoutTwo-body-content-pic">
                {/* <img src={micPic} alt="pic" /> */}
                <div className="layoutTwo-body-content-pic-text">
                  A standard, more simplistic website design focused more on
                  text content and info than visual design.
                </div>
              </div>
            </div>
            <div className="layoutTwo-body-content-texts">
              <div className="layoutTwo-body-content-text">
                <h4>Latin Filler</h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </p>
              </div>
              <div className="layoutTwo-body-content-text">
                <h4>Latin Filler</h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </p>
              </div>
              <div className="layoutTwo-body-content-text">
                <h4>Latin Filler</h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="layoutTwo-footer">
          <h3>Footer stuff</h3>
        </div>
      </div>
    </>
  );
};

export default LayoutTwo;
