import React from "react";
import logo from "../Pictures/pod_logo_one_white.png";
import micPic from "../Pictures/matt-botsford-OKLqGsCT8qs-unsplash.jpg";
import { Link, useLocation } from "react-router-dom";
import MouseTrail from "./mouseTrail";
import { useState, useEffect } from "react";

const LayoutFour = () => {
  return (
    <div className="layoutFour-content">
      <div className="layoutFour-section-one">
        <div className="layoutFour-image-container">
          <img src={logo} alt="logo" />
        </div>
        <div className="layoutFour-nav">
          <div className="layoutFour-nav-item">
            <Link to="/LayoutOne">
              <h3>Layout 1</h3>
            </Link>
          </div>
          <div className="layoutFour-nav-item">
            <Link to="/LayoutTwo">
              <h3>Layout 2</h3>
            </Link>
          </div>
          <div className="layoutFour-nav-item">
            <Link to="/LayoutThree">
              <h3>Layout 3</h3>
            </Link>
          </div>
          <div className="layoutFour-nav-item">
            <Link to="/LayoutFour">
              <h3>Layout 4</h3>
            </Link>
          </div>
        </div>
      </div>
      <div className="layoutFour-section-two">
        <div className="layoutFour-section-two-image">
          <div className="layout-four-section-two-text">
            <p>
              Visual site with a greater emphasis on design and hover effects
            </p>
          </div>
        </div>
      </div>
      <div className="layoutFour-section-three">
        <div className="layout-four-section-three-text">
          <h4>Additional Text</h4>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.{" "}
          </p>
        </div>
      </div>
    </div>
  );
};

export default LayoutFour;
