import logo from "./logo.svg";
import "./App.css";
import LayoutOne from "./Components/LayoutOne";
import LayoutTwo from "./Components/LayoutTwo";
import LayoutThree from "./Components/LayoutThree";
import LayoutFour from "./Components/LayoutFour";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  BrowserRouter,
  Link,
  useLocation,
} from "react-router-dom";
import MouseTrail from "./Components/mouseTrail";
import { useEffect, useState } from "react";

function App() {
  // const location = useLocation();
  // const [loc, setLoc] = useState(false)
  // const allowedRoute = ['/LayoutThree']

  // console.log("check the local dawg:", location);

  // const mouseTrailActive = allowedRoute.includes(location.pathname)

  // useEffect(() => {
  //   if (location.pathname === "/LayoutThree") {
  //     setLoc(true)
  //   }
  //   else {
  //     setLoc(false)
  //   }
  // }, [location.pathname])

  return (
    <>
      <Routes>
        <Route path="/" exact element={<LayoutOne />} />
        <Route
          path="/LayoutOne"
          exact
          element={<LayoutOne />}
        />
        <Route path="/LayoutTwo" element={<LayoutTwo />} />
        <Route
          path="/LayoutThree"
          element={<LayoutThree />}
        />
        <Route
          path="/LayoutFour"
          element={<LayoutFour />}
        />
      </Routes>

      {/* {mouseTrailActive && loc === true && (
        <MouseTrail />
      )} */}
    </>
    // <div className="body-main">

    //   <div className="header-main">
    //     <div className="body"></div>
    //     <p>Pee Poo</p>
    //   </div>
    //   <div className="footer-main"></div>
    // </div>
  );
}

export default App;
