import React from "react";
import logo from "../Pictures/pod_logo_one_dark.png";
import micPic from "../Pictures/matt-botsford-OKLqGsCT8qs-unsplash.jpg";
import { Link, useLocation } from "react-router-dom";
import MouseTrail from "./mouseTrail";
import { useState, useEffect } from "react";

const LayoutThree = () => {
  const location = useLocation();
  const [loc, setLoc] = useState(false);
  const allowedRoute = "/LayoutThree";

  console.log("check the local dawg:", location);

  //const mouseTrailActive = allowedRoute.includes(location.pathname)

  useEffect(() => {
    //   if (location.pathname === "/LayoutThree") {
    //     setLoc(true)
    //   }
    //   else {
    //     setLoc(false) }
    setLoc(location.pathname === allowedRoute);
  }, [location.pathname]);

  return (
    <>
      {/* {loc && (
        <MouseTrail />
      )} */}
      <div className="layoutThree-content">
        <div className="layoutThree-nav">
          <div className="layoutThree-nav-item">
            <Link to="/LayoutOne">
              <h3>Layout 1</h3>
            </Link>
          </div>
          <div className="layoutThree-nav-item">
            <Link to="/LayoutTwo">
              <h3>Layout 2</h3>
            </Link>
          </div>
          <div className="layoutThree-nav-item">
            <Link to="/LayoutThree">
              <h3>Layout 3</h3>
            </Link>
          </div>
          <div className="layoutThree-nav-item">
            <Link to="/LayoutFour">
              <h3>Layout 4</h3>
            </Link>
          </div>
        </div>
        <div className="layoutThree-logo">
          <div className="layoutThree-logo-img-container">
            <img src={logo} alt="logo" />
          </div>
          <div className="layoutThree-logo-text">
            <h4>
              Simplistic central site with emphasis on visual and light effects
            </h4>
          </div>
        </div>
      </div>
    </>
  );
};

export default LayoutThree;
